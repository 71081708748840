import React from 'react';

import { Navigate, Route, RouteObject } from '@plasma/ui.application.router';

import { layoutRoutes } from './components/layout';

export const routes: RouteObject[] = [
  new Route({
    path: '/',
    element: () => <Navigate to="/apis" replace />,
  }),
  ...layoutRoutes,
];
