import React from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import translations from './i18n';
import { LayoutProps } from './layout';
import help from './layout.help';

function useLayoutHook(props: LayoutProps) {
  useResourceBundle(translations, undefined, 'a818229b-df09-4a8a-a3b3-0d9cbe577bf0');
  useHelpBundle(help);

  const { t } = useTranslation();

  const [count, setCount] = React.useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  return {
    t,
    count,
    increment,
    decrement,
  };
}

export default useLayoutHook;
