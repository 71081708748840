import React, { memo, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';

import { APIView } from '../../components/features/ApiView/ApiView';
import useApisHook from '../apis/apis.hook';
import * as styled from './docs.styles';
import themes from './themes';

export interface DocsProps {
  children?: ReactNode;
  /**
   * Defines custom className
   */
  className?: string;
  /**
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = DocsProps & ThemeOverride;

function Docs(props: Props) {
  const { children, className, style, size, themeMode } = props;

  //load json file from server
  const [swaggerDefinition, setSwaggerDefinition] = React.useState(null);
  const { id } = useParams();
  const logic = useApisHook();

  useEffect(() => {
    const fetchData = async () => {
      const documentId = id || "";
      try {
        const swaggerDocument = await logic.getFileContent(documentId);
        setSwaggerDefinition(swaggerDocument);
      } catch (error) {
        console.error("Failed to fetch swagger document:", error);
      }
    };

    // Call the async function
    fetchData();

    return () => {
      // Cleanup actions if needed
    };
  }, [id]);

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Docs className={`docs ${className ?? ''}`.trim()} style={style}>
        <APIView swaggerDefinition={swaggerDefinition} />
        {children}
      </styled.Docs>
    </ThemeProvider>
  );
}

export default memo(Docs) as unknown as typeof Docs;
