import { useEffect, useState } from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import { services } from '../../api/openAPIAgent';
import { OpenAPIDto } from '../../models/dtos/open-api/openapiDto';
import help from './apis.help';
import translations from './i18n';



function useApisHook() {
  useResourceBundle(translations, undefined, '4f8271c8-5254-4a5f-bb4c-5abd2f100758');
  useHelpBundle(help);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [apiList, setAPIList] = useState<OpenAPIDto[]>([]);

  useEffect(() => {
    fetchOpenAPIDocuments();
  }, []);


  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  const downloadSwagger = (swaggerDocument: string, name: string) => {
    const jsonString = JSON.stringify(swaggerDocument, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}`;

    // Append the link to the document and trigger a click
    document.body.appendChild(a);
    a.click();

    // Remove the link from the document
    document.body.removeChild(a);

    // Revoke the URL to free up resources
    URL.revokeObjectURL(url);
  };

  const fetchOpenAPIDocuments = async () => {
    try {
      const apiDocuments = (await services.apiDocuments.getAll()).data;
      setAPIList(apiDocuments);
    } catch (error) {
      console.error('Error fetching api list:', error);
    }
  };

  const getFileContent = async (id: string) => {
    try {
      setLoading(true);
      const response = (await services.apiDocuments.getDocument(id)).data;
      const jsonObject = JSON.parse(response.document);
      return jsonObject;
    } catch (error) {
      console.error('There has been a problem with retrieving the file', error);

      // Optionally, you can handle the error further or re-throw it for the caller to handle
      throw error;
    } finally {
      // Ensure loading state is reset whether the request was successful or failed
      setLoading(false);
    }
  }

  return {
    t,
    count,
    loading,
    increment,
    decrement,
    downloadSwagger,
    getFileContent,
    apiList,
  };
}

export default useApisHook;
