import React from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/

import { Route } from '@plasma/ui.application.router';

const Docs = React.lazy(() => import('.'));

export const DocRoutes = [
  new Route({
    path: 'docs/:id',
    element: Docs,
    children: [],
  }),
];
