import React, { useEffect, useMemo } from "react";

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';

import Form from '@plasma/ui.input.form';

import useApisHook from "../../../pages/apis/apis.hook";
import useVersionHistoryHook from "./version-history.hooks";

interface Props {
  apiId: string,
  name: string
}

function VersionHistory(props: Props) {
  type DocumentInfo = {
    id: string;
    version: string;
  };

  const logic = useApisHook();

  const columns = [
    {
      title: 'Version',
      dataIndex: 'version',
      sorter: (a, b) => a.version.localeCompare(b.version),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
      render: (_, row) => {
        return (
          <Button type="primary" icon={<DownloadOutlined />} onClick={() => {
            const handleDownload = async () => {
              const swaggerDocument = await logic.getFileContent(row.id);
              const fileName = `${props.name.replace(/\s/g, '_')}.${row.version}.swagger.json`;
              logic.downloadSwagger(swaggerDocument, fileName);
            };
            handleDownload();
          }} title="Download Swagger" />
        );
      },
    },
  ];

  const { fetchAPIVersions, documentList } = useVersionHistoryHook();

  useEffect(() => {
    if (props.apiId) {
      fetchAPIVersions(props.apiId);
    }
  }, [props.apiId, fetchAPIVersions]);

  const dataSource: DocumentInfo[] = useMemo(() => {
    return documentList.map((document) => ({
      key: document.id, // Ant Design Tables expect a `key` prop for each row
      id: document.id,
      version: document.version,
    }));
  }, [documentList]);

  return (
    <Form>
      <Table
        columns={columns}
        size="small"
        dataSource={dataSource}
        pagination={false}
      />
    </Form>
  )
}

export default VersionHistory;