/* eslint-disable no-console */
import React from 'react';

import SwaggerUI from 'swagger-ui-react';

import keycloak from '../../../utils/keycloak';
import 'swagger-ui-react/swagger-ui.css';
import SmsSpin from '../../core/SmsSpin/SmsSpin';

interface Request {
  [k: string]: any;
}

const requestInterceptor = (req: Request, token: string): Request => {
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

export const APIView = ({ swaggerDefinition }: { swaggerDefinition: any }) => {
  const styles = {
    root: {
      backgroundColor: '#f0f2f5',
      color: 'white',
      padding: '20px',
      borderRadius: '5px',
    },
  };

  if (!keycloak.authenticated) {
    return <div>Loading Keycloak...</div>;
  }

  if (swaggerDefinition) {
    return (
      <div style={styles.root}>
        <SwaggerUI
          requestInterceptor={(req) => requestInterceptor(req, keycloak.token ?? '')}
          spec={swaggerDefinition}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SmsSpin />
    </div>
  );
};
