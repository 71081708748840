import baseStyled, { css as baseCss, ThemedCssFunction, ThemedStyledInterface } from 'styled-components';

import { DocsTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<DocsTheme>;
const css = baseCss as ThemedCssFunction<DocsTheme>;

export const Docs = styled.div`
  width: 100%;
  height: 100%;
`;

const RoundButton = styled.button`
  padding: 10px 20px; // Example padding
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darkblue;
  }
`;