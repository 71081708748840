import baseStyled, { css as baseCss, ThemedCssFunction, ThemedStyledInterface } from 'styled-components';

import { ApisTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<ApisTheme>;
const css = baseCss as ThemedCssFunction<ApisTheme>;

export const Apis = styled.div`
  width: 100%;
  height: 100%;

  .tableContainer {
    height: 100%;

    .ant-spin {
      height: 100% !important;
    }

    [role='table']:first-child {
      height: 100% !important;
      background-color: #ffffff;

      > :not([role]) {
        height: 100%;
      }

      > :first-child {
        > [role='rowgroup'] {
          height: calc(100% - 40px) !important;
        }
      }
    }
  }
`;

export const RoundButtonSpace = styled.div`
  display: flex;
  gap: 2px;
`;
