import { useState } from 'react';

import { useTranslation } from '@plasma/ui.utils.i18n';

import { services } from '../../../api/openAPIAgent';
import { DocumentVersionsDto } from '../../../models/dtos/open-api/openapiDto';


function useVersionHistoryHook() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [documentList, setDocumentList] = useState<DocumentVersionsDto[]>([]);

  const fetchAPIVersions = async (id: string) => {
    try {
      setLoading(true);
      const documentVersions = (await services.apiDocuments.getDocumentVersions(id)).data;
      setDocumentList(documentVersions);
    } catch (error) {
      console.error('Error fetching document versions:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    t,
    loading,
    documentList,
    fetchAPIVersions
  };
}

export default useVersionHistoryHook;
