import baseStyled, { css as baseCss, ThemedCssFunction, ThemedStyledInterface } from 'styled-components';


import { LayoutTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<LayoutTheme>;
const css = baseCss as ThemedCssFunction<LayoutTheme>;

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

