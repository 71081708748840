import React from 'react';
/*
To import child routes, insert the following code:
import { <childComponent>Routes } from './components/<child-folder>';
*/

import { Route } from '@plasma/ui.application.router';
import { ApiRoutes } from '../../pages/apis';
import { DocRoutes } from '../../pages/docs';

const Layout = React.lazy(() => import('.'));

export const layoutRoutes = [
  new Route({
    path: '',
    element: Layout,
    children: [...ApiRoutes, ...DocRoutes],
  }),
];
