import React, { ReactNode, useMemo, useState } from 'react';

import { DownloadOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button as RoundButton, Modal } from 'antd';

import { useNavigate } from '@plasma/ui.application.router';
import DataGrid, { ColumnType } from '@plasma/ui.display.data-grid';
import Button from '@plasma/ui.general.button';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';

import SmsSpin from '../../components/core/SmsSpin/SmsSpin';
import VersionHistory from '../../components/features/VersionHistory/version-history';
import useApisHook from './apis.hook';
import * as styled from './apis.styles';
import themes from './themes';

export interface ApisProps {
  children?: ReactNode;
  /**
   * Defines custom className
   */
  className?: string;
  /**
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = ApisProps & ThemeOverride;

function Apis(props: Props) {
  const { children, className, style, size, themeMode } = props;

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Apis className={`apis ${className ?? ''}`.trim()} style={style}>
        <ApiList />
        {children}
      </styled.Apis>
    </ThemeProvider>
  );
}

function ApiList() {
  type ApiInfo = {
    id: string;
    key: string;
    grouping: string;
    name: string;
    description: string;
    version: string;
    isOnline: string;
    definition: string;
  };
  const navigate = useNavigate();
  const logic = useApisHook();
  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false)
  const [currentApiId, setCurrentApiId] = useState<string>('');
  const [currentApiName, setCurrentApiName] = useState<string>('');

  const columnsWithProfile: ColumnType<ApiInfo>[] = React.useMemo(
    () => [
      {
        Header: 'Grouping',
        accessor: 'grouping',
        desc: false,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }: { value: string; row: any }) => {
          if (row.depth > 0) {
            return (
              <Button
                type="tertiary"
                title={value}
                onClick={() => {
                  navigate(`/docs/${row.original.key}`);
                }}
              />
            );
          }
          return null;
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Version',
        accessor: 'version',
      },
      {
        Header: 'Type',
        accessor: 'isOnline',
      },
      {
        Header: 'Action',
        accessor: 'definition',
        Cell: ({ row }: { row: any }) => {
          if (row.depth > 0) {
            return (
              <styled.RoundButtonSpace>
                <RoundButton
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={async () => {
                    const swaggerDocument = await logic.getFileContent(row.original.key);
                    const fileName = `${row.original.name.replace(/\s/g, '_')}.${row.original.version}.swagger.json`;
                    logic.downloadSwagger(swaggerDocument, fileName);
                  }}
                  title="Download Swagger"
                />
                <RoundButton type="primary" icon={<HistoryOutlined />}
                  onClick={() => {
                    setCurrentApiId(row.original.id);
                    setCurrentApiName(row.original.name);
                    setHistoryModalOpen(true);
                  }}
                  title={"History"} />
              </styled.RoundButtonSpace>
            );
          }
          return null;
        },
      }
    ],
    [],
  );

  const data: ApiInfo[] = useMemo(() => {
    const apiArray = logic.apiList.map((api) => {
      return {
        id: api.id,
        key: api.documentId,
        grouping: api.groupName,
        name: api.name,
        description: api.description,
        version: api.version,
        isOnline: 'Document',
        definition: '' //TODO: Get swagger json
      };
    });
    return apiArray;
  }, [logic.apiList]);

  return (
    <styled.Apis>
      <div className="tableContainer">
        {logic.loading && <SmsSpin />} {
          <>
            <DataGrid<ApiInfo>
              disableRowsSelection
              disablePagination
              disableToolHeader
              disableDragAndDrop
              disableGroupBy
              disableSortBy
              disableAllColumnMoving
              skipAutoReset
              columns={columnsWithProfile}
              groupColumns={['grouping']}
              data={data}
              rowsHeight={40}
              className={'thing-hierarchy-details-table'}
              onRowSelection={({ selectedRows }) => { }}
            />
            <Modal
              title="Version History"
              visible={historyModalOpen}
              onCancel={() => setHistoryModalOpen(false)}
              maskClosable
              closable
              footer={[
                <RoundButton type="primary" key="close" onClick={() => setHistoryModalOpen(false)}>
                  Close
                </RoundButton>
              ]}
            >
              <VersionHistory apiId={currentApiId} name={currentApiName} />
            </Modal>
          </>

        }
      </div>
    </styled.Apis>
  );
}

export default Apis;
