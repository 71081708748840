import axios from 'axios';

import { OpenAPIDocumentDto } from '../models/dtos/open-api/openapiDocumentDto';
import { OpenAPIDto, DocumentVersionsDto } from '../models/dtos/open-api/openapiDto';

// Define a type for window.env
interface WindowEnv {
  BACKEND_URL: string;
  BACKEND_VERSION?: string;
}

// Extend the Window interface to include env
declare global {
  interface Window {
    env: WindowEnv;
  }
}

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = window.env.BACKEND_URL;

const apiVersion = window.env.BACKEND_VERSION ?? '1.0';

const apiDocuments = {
  getAll: () => axiosInstance.get<OpenAPIDto[]>('api', { headers: { 'x-api-version': apiVersion } }),
  getDocument: (id: string) => axiosInstance.get<OpenAPIDocumentDto>(`open-api-document/${id}`, { headers: { 'x-api-version': apiVersion } }),
  getDocumentVersions: (id: string) => axiosInstance.get<DocumentVersionsDto[]>(`api/${id}/versions`, { headers: { 'x-api-version': apiVersion } }),
}

const services = { apiDocuments };

export { services, axiosInstance };
